/* eslint @typescript-eslint/no-explicit-any: 0 */
import CommunityApi from '@/apis/community.api';
import VueRouter from '@/router';
import settings from '@/settings';

export default class CommunityService {
    public static async GetStylingForCommunity(): Promise<Record<string, any> | null> {
        const {community} = VueRouter.currentRoute.params;
        if (community !== undefined) {
            const data = await CommunityApi.getStyle(community);
            if (data.community !== undefined && data.community['font-fam'] !== undefined) {
                let fontName = data.community['font-fam']
                    .replaceAll('font-family:', '')
                    .replaceAll(';', '')
                    .replaceAll('\'', '')
                    .trim();
                if (fontName.indexOf(',') >= 0) fontName = fontName.substr(0, fontName.indexOf(','));
                document.documentElement.style.setProperty('--community-font-fam', `'${fontName}'`);

                if (data.community['font-url'] !== undefined) {
                    if (data.community['font-url'].lastIndexOf(settings.objectStorage) >= 0) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore TS2304
                        const font = new FontFace(fontName, `url(${data.community['font-url']})`);
                        font.load()
                            .then((loadedFont: any) => {
                                (document as any).fonts.add(loadedFont);
                            });
                    } else {
                        console.log('Loading Font');
                        this.loadFontFromUrl(data.community['font-url']);
                    }
                }

                if (data.web?.font_source_google !== undefined && data.web.font_source_google === '1' && data.web['body_google_font-family'] !== '') {
                    this.loadGoogleFontFromFamilyNameAndWeight(
                        data.web['body_google_font-family'],
                        data.web['body_font-weight'],
                    );
                }
            }
            if (data.web === undefined) {
                return data;
            }
            Object.entries(data.web)
                .forEach((item) => {
                    const [key, rawValue] = item;
                    const value = String(rawValue);
                    const property = key.replaceAll('_', '-');

                    // Skip all web stats that are also defined in stats scope, to prevent race condition
                    if (data.stats[item[0]] !== undefined) {
                        return;
                    }

                    // Skip all web stats that are also defined in stats scope, to prevent race condition.
                    // Also skip if stats scope is not loaded yet, because this means that it will be applied after the web scope anyway
                    if (data.stats?.[item[0]] !== undefined) {
                        return;
                    }

                    if (property.indexOf('image') !== -1) {
                        document.documentElement.style.setProperty(`--${property}`, `url(${value})`);
                    } else if (/^[1-9]+[0-9]*$/.test(value)) {
                        document.documentElement.style.setProperty(`--${property}`, `${value}px`);
                    } else {
                        document.documentElement.style.setProperty(`--${property}`, (value as string));
                    }
                });
            return data;
        }
        return null;
    }

    public static addFontFaceFromNameAndUrl(name: string, url: string): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore TS2304
        const face = new FontFace(name, `url(${url})`);
        face.load()
            .then((loadedFont: any) => {
                (document as any).fonts.add(loadedFont);
            });
    }

    private static loadFontFromUrl(url: string) {
        const documentHead = document.head;
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;
        documentHead.appendChild(link);
    }

    public static loadGoogleFontFromFamilyNameAndWeight(name: string, weight = '100..950') {
        const url = `https://fonts.googleapis.com/css2?family=${name}:wght@${weight}&display=swap`;
        this.loadFontFromUrl(url);
    }
}
